<template>
  <div class="pb-4" style="background-color: #fff" id="page-pendaftaran">
    <b-container fluid>
      <b-row style="padding-top: 15px">
        <b-col cols="12" md="12" lg="8" xl="8">
          <div
            style="
              width: 100%;
              height: 40px;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              background-color: ;
            "
          >
            <h6
              style="font-weight: 700; font-size: 22px; margin-bottom: 0" class="custom-title-color-black">
              Statistik Kunjungan
            </h6>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="12" lg="12" xl="12" class="p-0">
          <hr />
        </b-col>
      </b-row>
      <b-row class="m-0 p-0">
        <b-col cols="6">
          <label for="tanggal_awal">Tanggal Awal</label>
          <date-picker
            style="width: 100%"
            id="tanggal_awal"
            format="DD-MM-YYYY"
            v-model="dataFilter.tanggal_awal"
          ></date-picker>
        </b-col>
        <b-col cols="6">
          <label for="tanggal_akhir">Tanggal Akhir</label>
          <date-picker
            style="width: 100%"
            id="tanggal_akhir"
            format="DD-MM-YYYY"
            v-model="dataFilter.tanggal_akhir"
          ></date-picker>
        </b-col>
        <b-col cols="6">
          <label for="jenis_kelamin">Jenis Kelamin</label>
          <Multiselect
            id="jenis_kelamin"
            v-model="dataFilter.jenis_kelamin"
            :options="listJenisKelamin"
            :multiple="false"
            :searchable="true"
            :close-on-select="true"
            :show-labels="false"
            label="text"
            track-by="value"
            placeholder="-- Pilih Jenis Kelamin --"
            size="sm"
          ></Multiselect>
        </b-col>
        <b-col cols="6">
          <label for="pasien">Pasien</label>
          <Multiselect
            id="pasien"
            v-model="dataFilter.pasien"
            :options="listPasien"
            :multiple="false"
            :searchable="true"
            :close-on-select="true"
            :show-labels="false"
            label="text"
            track-by="value"
            placeholder="-- Pilih Pasien --"
            size="sm"
          ></Multiselect>
        </b-col>
        <b-col cols="12">
          <div class="d-flex justify-content-end">
            <b-button 
              class="mr-2"
              :disabled="busy"
              @click="reset()"
              variant="danger"
            >Reset</b-button>
            <b-button
              :disabled="busy"
              @click="cari()"
              variant="success"
            >Cari</b-button>
          </div>
        </b-col>
        <b-col cols="3">
          <ByUmur v-if="!busy" :by_umur="by_umur" />
        </b-col>
        <b-col cols="3">
          <ByAsuransi v-if="!busy" :by_asuransi="by_asuransi" />
        </b-col>
        <b-col cols="3">
          <ByPoli v-if="!busy" :by_poli="by_poli" />
        </b-col>
        <b-col cols="3">
          <ByStatusPulang v-if="!busy" :by_status_pulang="by_status_pulang" />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import ByUmur from './by_umur.vue'
import ByAsuransi from './by_asuransi.vue'
import ByPoli from './by_poli.vue'
import ByStatusPulang from './by_status_pulang.vue'
export default {
  components:{
    ByUmur,
    ByAsuransi,
    ByPoli,
    ByStatusPulang,
  },
  data() {
    return {
      busy: false,
      dataFilter: {
        jenis_kelamin: null,
        tanggal_awal: null,
        tanggal_akhir: null,
        pasien: null,
      },
      listJenisKelamin:[
        { value: null , text: "Semua"},
        { value: "L" , text: "Laki - Laki"},
        { value: "P" , text: "Perempuan"},
      ],
      listPasien:[
        { value: null , text: "Semua"},
        { value: "lama" , text: "Lama"},
        { value: "baru" , text: "Baru"},
      ],
      by_umur: [],
      by_asuransi: [],
      by_status_pulang: [],
      by_poli: [],
    }
  },
  computed: {
    
  },
  activated() {
    const vm = this
    vm.reset()
  },
  methods: {
    async cari(){
      const vm = this
      vm.busy = false
      try {
        const res = await vm.$axios.post('/kunjungan/laporan_grafik_statistik_kunjungan', {
          tanggal_awal: vm.$moment(vm.dataFilter.tanggal_awal),
          tanggal_akhir: vm.$moment(vm.dataFilter.tanggal_akhir),
          jenis_kelamin: vm.dataFilter.jenis_kelamin ? vm.dataFilter.jenis_kelamin.value : null,
          pasien: vm.dataFilter.pasien ? vm.dataFilter.pasien.value : null,
        })
        // console.log('laporan_grafik_statistik_kunjungan', res)
        if(res.data.status == 200){
          // console.log('laporan_grafik_statistik_kunjungan', res.data.data)
          vm.by_umur = res.data.data.data_by_umur
          vm.by_asuransi = res.data.data.data_by_asuransi
          vm.by_status_pulang = res.data.data.data_by_status_pulang
          vm.by_poli = res.data.data.data_by_poli
        }
      } catch (error) {
        console.log(error)
      } finally {
        vm.busy = false
      }
    },
    reset(){
      const vm = this
      vm.dataFilter = {
        jenis_kelamin: vm.listJenisKelamin[0],
        pasien: vm.listPasien[0],
        tanggal_awal: new Date(vm.$moment(vm.$store.state.tanggal_sesi).subtract(1, 'months')),
        tanggal_akhir: new Date(vm.$store.state.tanggal_sesi),
      }
      vm.cari()
    }
  },
}
</script>