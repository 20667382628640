<template>
  <div class="card p-3">
    <h5 class="custom-title-card"><strong>Menurut Umur</strong></h5>
    <canvas id="myChart" ref="serviks" style="height: 1000px; width: 100%"></canvas>
    <h6 class="text-right mt-2"><strong>Total {{by_umur.jumlah_total}} pasien</strong></h6>
    <div>
      <div class="d-flex justify-content-between" v-for="(item, key) of by_umur" :key="key">
        <div class="d-flex align-middle">
          <svg :style="`color: ${backgroundColor[key % backgroundColor.length]};`" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-dice-1-fill" viewBox="0 0 16 16">
            <path d="M3 0a3 3 0 0 0-3 3v10a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3V3a3 3 0 0 0-3-3H3zm5 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"/>
          </svg>
          <b class="ml-1">{{item.jenis}}</b></div>
        <div>{{item.jumlah}} ({{Math.round(item.jumlah / total*10000)/100}}%)</div>
      </div>
    </div>
  </div>
</template>

<script>
import {Chart, registerables } from 'chart.js'
Chart.register(...registerables );
export default {
  props: [
    'by_umur',
  ],
  data() {
    return {
      busy: false,
      myChart: null,
      total: 0,
      backgroundColor: [
        '#e3342f',
        '#f6993f',
        '#ffed4a',
        '#38c172',
        '#4dc0b5',
        '#3490dc',
        '#6574cd',
        '#9561e2',
        '#f66d9b',
      ],
    }
  },
  computed: {
    
  },
  watch: {
    'by_umur': {
      handler(data) {
        // console.log('ubah dataKunjungan', data)
        data
        const vm = this
        vm.getData()
      },
      deep: true
    },
  },
  async activated() {
    const vm = this
    vm.myChart = new Chart(this.$refs.serviks, {
      type: 'doughnut',
      data: {
        labels: [
          'Balita (0-5 Tahun)',
          'Kanak-Kanak (6-11 Tahun)',
          'Remaja-Awal (12-16 Tahun)',
          'Remaja-Akhir (17-25 Tahun)',
          'Dewasa-Awal (26-35 Tahun)',
          'Dewasa-Akhir (36-45 Tahun)',
          'Lansia-Awal (46-55 Tahun)',
          'Lansia-Akhir (56-65 Tahun)',
          'Manula (> 65 Tahun)',
        ],
        datasets: [{
          label: 'My First Dataset',
          data: [],
          backgroundColor: vm.backgroundColor,
          hoverOffset: 4
        }]
      },
      options: {
        plugins: {
          legend: {
              display: false
          },
        }
      }
    })
    vm.myChart.update()
    await vm.getData()
  },
  methods: {
    async getData(){
      const vm = this
      vm.busy = false
      try {
        console.log('jalan')
        const label = []
        const data = []
        vm.total = 0
        for (let i = 0; i < vm.by_umur.length; i++) {
          const x = vm.by_umur[i];
          label.push(x.jenis)
          data.push(x.jumlah)
          vm.total += x.jumlah
        }
        vm.myChart.data.labels = label;
        vm.myChart.data.datasets[0].data = data;
        vm.myChart.update()
      } catch (error) {
        vm.triggerAlert({ variant: "danger", msg: 'Terjadi kesalahan system', showing: true });
        console.log(error)
      } finally {
        vm.busy = false
      }
    },
    triggerAlert(event) {
      let vm = this;
      vm.$store.commit("set_alert", event);
    },
  },
}
</script>